import React from "react";
import "./../index.scss";
import "./artist.scss";
import { Header, Footer, Contact } from "./../components";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { toggleState } from "./../components/contact";

export default ({ data }) => {
  return (
    <>
      <Helmet>
        <title>Showelli | Artists</title>
        <meta
          name="description"
          content="Showelli's professional artists all listed in one place. Available now for your events, parties or venue."
        />
        <meta
          name="keywords"
          content="Showelli, dancers, performers, choreography, music videos, concerts, backup dancers, fire acts"
        />
      </Helmet>
      <Header />
      <div className="hero is-small is-primary-50">
        <div className="hero-body">
          <div className="container">
            <h2 className="title">Acts</h2>
          </div>
        </div>
      </div>
      <div className="has-text-centered-mobile">
        <section className="section is-primary-50">
          <div className="container">
            <div className="columns is-vcentered is-centered">
              <div className="column is-4">
                <Img
                  fluid={data.acts11.childImageSharp.fluid}
                  alt={`Showelli Lion suit`}
                  style={{ maxHeight: "500px" }}
                />
              </div>
              <div className="column is-4 has-text-right">
                <h2 className="title">Leo And Loki the Lions 🦁</h2>
                <p>
                  Looking for something different?
                  Something bold and creative!
                  Leo and Loki are friendly and ready to be let out in the wild!
                  Message to book.
                </p>
                <br />
                <a
                  className="button is-primary"
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    toggleState();
                  }}
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="columns is-vcentered is-centered is-reversed">
              <div className="column is-4">
                <Img
                  fluid={data.acts12.childImageSharp.fluid}
                  alt={`Showelli Gatsby Girls`}
                  style={{ maxHeight: "500px" }}
                />
              </div>
              <div className="column is-4 has-text-right">
                <h2 className="title">Gatsby Girls</h2>
                <p>
                  Six traditional 1920's Gatsby dancers who put on a 4 minute flapper fun show to remember including confetti cannons and optional audience interaction!  Perfect for Weddings &amp; corporate events!
                </p>
                <br />
                <a
                  className="button is-primary"
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    toggleState();
                  }}
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="section is-primary-50">
          <div className="container">
            <div className="columns is-vcentered is-centered">
              <div className="column is-4">
                <Img
                  fluid={data.acts10.childImageSharp.fluid}
                  alt={`Showelli DISCO ball heads`}
                  style={{ maxHeight: "500px" }}
                />
              </div>
              <div className="column is-4 has-text-right">
                <h2 className="title">DiscoBall Heads</h2>
                <p>
                  Planning a disco event? Or need something fun and spectacular
                  at your venue? Book our disco ball heads "disco is music for
                  dancing and people will always want to dance!"
                </p>
                <br />
                <a
                  className="button is-primary"
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    toggleState();
                  }}
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="columns is-vcentered is-reversed is-centered">
              <div className="column is-4">
                <Img
                  fluid={data.acts9.childImageSharp.fluid}
                  alt={`Showelli LED ballerinas`}
                  style={{ maxHeight: "500px" }}
                />
              </div>
              <div className="column is-4 has-text-right">
                <h2 className="title">LED Ballerinas</h2>
                <p>
                  Let our sensational Light up ballerinas beautifully dance on
                  their tippy toes giving your event the sparkle and shine you
                  need.
                </p>
                <br />
                <a
                  className="button is-primary"
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    toggleState();
                  }}
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="section is-primary-50">
          <div className="container">
            <div className="columns is-vcentered is-centered">
              <div className="column is-4">
                <Img
                  fluid={data.acts8.childImageSharp.fluid}
                  alt={`Showelli Showgirls`}
                  style={{ maxHeight: "500px" }}
                />
              </div>
              <div className="column is-4 has-text-right">
                <h2 className="title">Show Girls</h2>
                <p>Looking for the EXTRA Glitz and Glam?</p>
                <br />
                <p>
                  Book the show girls for your event to add that extra glamour
                  factor
                </p>
                <br />
                <a
                  className="button is-primary"
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    toggleState();
                  }}
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="columns is-reversed is-vcentered is-centered">
              <div className="column is-4">
                <Img
                  fluid={data.acts7.childImageSharp.fluid}
                  alt={`Showelli vikings`}
                  style={{ maxHeight: "500px" }}
                />
              </div>
              <div className="column is-4 has-text-right">
                <h2 className="title">The Buff Vikings</h2>
                <p>
                  Get ready for a raid! Grab these hunky Vikings to raunch up
                  your night with naughty Viking games!
                </p>
                <br />
                <p>
                  Book your Vikings for hen parties, birthday parties, brunches,
                  ladies nights and more!
                </p>
                <br />
                <a
                  className="button is-primary"
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    toggleState();
                  }}
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="section is-primary-50">
          <div className="container">
            <div className="columns is-vcentered is-centered">
              <div className="column is-4">
                <Img
                  fluid={data.acts1.childImageSharp.fluid}
                  alt={`Showelli Drag act`}
                />
              </div>
              <div className="column is-4">
                <h2 className="title">Drag Acts</h2>
                <p>
                  Our Queens are ready to make you laugh, make you cry and
                  sashay the night away.
                </p>
                <br />
                <p>
                  Book your drag act for a karaoke night or host your event.
                </p>
                <br />
                <a
                  className="button is-primary"
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    toggleState();
                  }}
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="columns is-reversed is-vcentered is-centered">
              <div className="column is-4">
                <Img
                  fluid={data.acts3.childImageSharp.fluid}
                  alt={`Showelli saxophonist`}
                  style={{ maxHeight: "500px" }}
                />
              </div>
              <div className="column is-4 has-text-right">
                <h2 className="title">Saxophonist</h2>
                <p>
                  Jazz up your event with the smooth vibes from our spectacular
                  Saxophonist.
                </p>
                <br />
                <p>
                  Book your Saxophonist for a brunch, party night or a special
                  event and all that Jazz.
                </p>
                <br />
                <a
                  className="button is-primary"
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    toggleState();
                  }}
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="section is-primary-50">
          <div className="container">
            <div className="columns is-vcentered is-centered">
              <div className="column is-4">
                <Img
                  fluid={data.acts5.childImageSharp.fluid}
                  alt={`Showelli electric violinist`}
                  style={{ maxHeight: "500px" }}
                />
              </div>
              <div className="column is-4">
                <h2 className="title">Electric Violin</h2>
                <p>
                  LED light up violin gives you a Marbella and Ibiza atmosphere
                  along side a DJ to liven up your event unlike any other!
                </p>
                <br />
                <p>Book the vibe-olin of the party now.</p>
                <br />
                <a
                  className="button is-primary"
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    toggleState();
                  }}
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="columns is-reversed is-vcentered is-centered">
              <div className="column is-4">
                <Img
                  fluid={data.acts4.childImageSharp.fluid}
                  alt={`Showelli electric first act`}
                />
              </div>
              <div className="column is-4 has-text-right">
                <h2 className="title">Fire</h2>
                <p>
                  Warm yourself up with the breathtaking fire expierence. With
                  swords, fans, fire eating &amp; breathing, fire poi and body
                  burning.
                </p>
                <br />
                <p>Light up the party that you won't want to put out.</p>
                <br />
                <a
                  className="button is-primary"
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    toggleState();
                  }}
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="section is-primary-50">
          <div className="container">
            <div className="columns is-vcentered is-centered">
              <div className="column is-4">
                <Img
                  fluid={data.acts6.childImageSharp.fluid}
                  alt={`Showelli electric first act`}
                />
              </div>
              <div className="column is-4">
                <h2 className="title">Duo Fusion</h2>
                <p>
                  Be amazed with our flying aerialist who can give you the wow
                  factor and make your guests dizzy after watching.
                </p>
                <br />
                <p>
                  They can perform many acts such as hoops, silks, pole and
                  flying duo acts that will leave you astounded.
                </p>
                <br />
                <a
                  className="button is-primary"
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    toggleState();
                  }}
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="columns is-vcentered is-centered is-reversed">
              <div className="column is-4">
                <Img
                  fluid={data.acts13.childImageSharp.fluid}
                  alt={`Showelli Festival Package`}
                  style={{ maxHeight: "500px" }}
                />
              </div>
              <div className="column is-4 has-text-right">
                <h2 className="title">The Festival Package </h2>
                <p className="content">
                  The Perfect set up for Festival like party's a 60 minute full out show pre prepared giving you the absolute Ibiza vibes! 
                  Perfect for University's, Fresher weeks, weddings and many more!
                  Include the musicians below:
                  <ul className="has-text-left">
                    <li>Sax</li>
                    <li>Trumpet</li>
                    <li>Dj</li>
                    <li>Two dancers</li>
                    <li>Singer</li>
                  </ul>
                </p>
                <br />
                <a
                  className="button is-primary"
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    toggleState();
                  }}
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
      <Contact />
    </>
  );
};

export const query = graphql`
  query {
    acts1: file(relativePath: { eq: "acts1.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    acts3: file(relativePath: { eq: "acts3.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    acts4: file(relativePath: { eq: "acts4.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    acts5: file(relativePath: { eq: "acts5.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    acts6: file(relativePath: { eq: "acts6.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    acts7: file(relativePath: { eq: "acts7.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    acts8: file(relativePath: { eq: "acts8.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    acts9: file(relativePath: { eq: "acts9.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    acts10: file(relativePath: { eq: "acts10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    acts11: file(relativePath: { eq: "acts11.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    acts12: file(relativePath: { eq: "acts12.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    acts13: file(relativePath: { eq: "acts13.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;
